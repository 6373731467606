
















import { Component } from 'vue-property-decorator';

import Detail from './../Detail.vue';

@Component
export default class DetailHorizontal extends Detail {
}
